import { css } from 'styled-components';
import { isMobile, media } from '@startlibs/utils';

export const stickyOnDesktop = () => !isMobile() && css`
  position: sticky;
  ${media.desktop `
    max-width: calc(100vw - 5rem - 15px);
    left: 2.5rem;
  `}
  ${media.mobile `
    max-width: calc(100vw - 15px);
    left: 0;
  `}
`
