import _ from 'lodash/fp'
import { getIsVivaLite } from '../hooks/useIsVivaLite'

var isVivaLite = '' 
getIsVivaLite().then(
    (result) => {
        isVivaLite = result
    }
)


/****************************** Json: WorklistMenus ***************************************/
export const WORKLIST_MENUS_SYSTEM_ADMINISTRATOR = { 
    studyBrowser: true,
    createBlankStudy: true,
    reports: true,
    upload: true,
    userManagement: true,
    auditLogs: true
}

export const WORKLIST_MENUS_RADIOLOGIST = { 
    studyBrowser: true,
    createBlankStudy: true,
    reports: true,
    upload: true,
    userManagement: false,
    auditLogs: false
}

export const WORKLIST_MENUS_RADIOLOGIST_TECHNICIAN = { 
    studyBrowser: true,
    createBlankStudy: true,
    reports: false,
    upload: true,
    userManagement: false,
    auditLogs: false
}

export const WORKLIST_MENUS_REFERRING_PHYSICIAN = { 
    studyBrowser: true,
    createBlankStudy: false,
    reports: false,
    upload: false,
    userManagement: false,
    auditLogs: false
}

export const WORKLIST_MENUS_SECRETARY= { 
    studyBrowser: true,
    createBlankStudy: true,
    reports: false,
    upload: true,
    userManagement: false,
    auditLogs: false
}

export const WORKLIST_MENUS_CUSTOM = { 
    studyBrowser: false,
    createBlankStudy: false,
    reports: false,
    upload: false,
    userManagement: false,
    auditLogs: false
}
/********************************************************************************************/

/****************************** Json: WorklistOptions ***************************************/
export const WORKLIST_OPTIONS_SYSTEM_ADMINISTRATOR = () => {
    return {
        viewStudy: isVivaLite ? false : true, 
        viewerLink: isVivaLite ? false : true, 
        launchInHoros:true, 
        downloadStudy:true, 
        downloadCD:true, 
        attachNonDicom:true, 
        studyNotes:true, 
        report:true, 
        reportWriteAccess: true,
        share:true, 
        markAsRead:true,
        activityLogs:true
    }
}

export const WORKLIST_OPTIONS_RADIOLOGIST = () => {
    return {
        viewStudy: isVivaLite ? false : true, 
        viewerLink: isVivaLite ? false : true, 
        launchInHoros:true, 
        downloadStudy:true, 
        downloadCD:true, 
        attachNonDicom:true, 
        studyNotes:true, 
        report:true,
        reportWriteAccess: true, 
        share:true, 
        markAsRead:true,
        activityLogs:true
    }
} 

export const WORKLIST_OPTIONS_RADIOLOGIST_TECHNICIAN = () => {
    return {
        viewStudy: isVivaLite ? false : true, 
        viewerLink: isVivaLite ? false : true, 
        viewStudy:true, 
        viewerLink:true, 
        launchInHoros:true, 
        downloadStudy:true, 
        downloadCD:true, 
        attachNonDicom:true, 
        studyNotes:true, 
        report:true, 
        reportWriteAccess: false,
        share:true, 
        markAsRead:false,
        activityLogs:false
    }
}

export const WORKLIST_OPTIONS_REFERRING_PHYSICIAN = () => {
    return {
        viewStudy: isVivaLite ? false : true, 
        viewerLink: isVivaLite ? false : true,    
        viewStudy:true, 
        viewerLink:true, 
        launchInHoros:true, 
        downloadStudy:true, 
        downloadCD:true, 
        attachNonDicom:true,  
        studyNotes:true, 
        report:true, 
        reportWriteAccess: false,
        share:false, 
        markAsRead:false,
        activityLogs:false
    } 
}

export const WORKLIST_OPTIONS_SECRETARY = () => {
    return {
        viewStudy: isVivaLite ? false : true,  
        viewerLink:false, 
        launchInHoros:false, 
        downloadStudy:false, 
        downloadCD:true, 
        attachNonDicom:true, 
        studyNotes:false, 
        report:true, 
        reportWriteAccess: false,
        share:true, 
        markAsRead:false,
        activityLogs:false
    }
} 

export const WORKLIST_OPTIONS_CUSTOM = () => {
    return {
        viewStudy:false, 
        viewerLink:false, 
        launchInHoros:false, 
        downloadStudy:false, 
        downloadCD:false, 
        attachNonDicom:false, 
        studyNotes:false, 
        report:false, 
        reportWriteAccess: false,
        share:false, 
        markAsRead:false,
        activityLogs:false
    }
} 

/********************************************************************************************/