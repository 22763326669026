import {getFetcher, postFetcher} from '@startlibs/utils'

const FAKE_POST = false || new URLSearchParams(window.location.search).get("fake")

const maybeFakePostFetcher = (FAKE_POST && process.env.NODE_ENV === 'development') ? (...v) => Promise.resolve(console.log(v)) : postFetcher

export const jwtGetFetcher = (jwt) => async (url, params = null, options) => {
  const availableJwt = await jwt
  return getFetcher(url, {noCache:Date.now(),...params}, {...options,headers: new Headers({'Authorization': `Bearer ${availableJwt}`})})
}
export const jwtPostFetcher = (jwt) => async (url, params, options) => {
  const availableJwt = await jwt
  return maybeFakePostFetcher(url, params, {...options,headers: {'Authorization': `Bearer ${availableJwt}`}})
}

export const jwtFormFetcher = (jwt) => (url, options) => (params) => jwtPostFetcher(jwt)(url, params, options)


export const authGetFetcher = (url,params,...args) => jwtGetFetcher(window.sessionStorage.getItem("jwt"))(url,{noCache:Date.now(),...params},args)
export const authPostFetcher = (url, params, options) => jwtPostFetcher(window.sessionStorage.getItem("jwt"))(url,params,options)

export const iframeAuthGetFetcher = (url, params = null, options) => getFetcher(url, {noCache:Date.now(),...params}, {...options,headers: new Headers({'Authorization': `Bearer ${new URLSearchParams(window.location.search).get("jwt")}`})})
export const iframeAuthPostFetcher = (url, params, options) => maybeFakePostFetcher(url, params, {...options,headers: new Headers({'Authorization': `Bearer ${new URLSearchParams(window.location.search).get("jwt")}`,'Content-Type': 'application/json; charset=UTF-8'})})


export const appJwt = () => new URLSearchParams(window.location.search).get("jwt") || window.sessionStorage.getItem("jwt")
export const appAuthenticatedUser = () => new URLSearchParams(window.location.search).get("username") || window.sessionStorage.getItem("username")

export const INTEGRATION_TO_BE_DONE = () => Promise.resolve()