import { Router } from '@reach/router'
import { Slot, StartlibsProvider } from '@startlibs/core'
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { customTheme, postFetcher } from '@startlibs/utils';
import { hot } from 'react-hot-loader/root'
import React, {useEffect, useState } from 'react'
import { CheckScrollOnBottom } from './components/CheckScrollOnBottom'
import { Notifications, setNotification } from '@startlibs/components'
import { getJwt, setJwt } from './hooks/useJwt';
import { PageLoadingSuspense } from './components/PageLoading'
import { ProviderErrorBoundary } from './pages/errors/ProviderError'
import { UserList } from './pages/UserList'
import StartlibsConfig from './UIConfig/StartlibsConfig'
import StyledTheme from './UIConfig/StyledTheme'
import { EditUser } from './pages/EditUser';
import { appJwt, appAuthenticatedUser, jwtGetFetcher, jwtPostFetcher } from './utils/authFetch';
import { lazyUserTemplate } from './utils/lazyUserTemplate';
import { setUsername, getUsername } from './hooks/useAuthenticatedUser';
import { setIsVivaLite } from './hooks/useIsVivaLite';
import { setSsoEnabled } from './hooks/useSsoEnabled';
import { setPacsdbName } from './hooks/usePacsdbName';

const Identity = React.forwardRef(({ children }, ref) => <React.Fragment>{children}
  <div ref={ref} />
</React.Fragment>)

const DefaultStyle = createGlobalStyle`
  ${customTheme('DefaultStyle')};
`

lazyUserTemplate.promise();

const authJson = {
  "password": "admin",
  "rememberMe": true,
  "username": "admin"
}

let authenticated = process.env.NODE_ENV !== 'development'

if (!authenticated) {
  // localhost environment: Go to server to authenticate
  // run with http://localhost:9001/?username=admin
  postFetcher('/api/authenticate', authJson).then(({ id_token }) => {
    setJwt(id_token);
    setUsername('admin')
  })
  // .catch(() => {
  //   // sets JWT manually
  //   setJwt('eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJhdXRoIjoicGFjc2RiY21zZGV2Iiwic3ViIjoiZXZhbmRybzVAcGFjc2RiY21zZGV2IiwiZXhwIjoxNjE3MDQzMzQ4fQ.jtGxXtPwymd9ax0JersRz3TWW_3zPZ80UZtlHVmmLfqxqSGUKf3RYp6vLudlmm52GlSW7XX0auTjU4UwiDTWeQ');
  //   setUsername('evandro5@pacsdbcmsdev');
  // })
} else {
  // Test and Prod Environment: get Jwt and username from URL
  setJwt(appJwt())
  setUsername(appAuthenticatedUser())
}

var referrer = document.referrer;

const App = () => {

  const [logPermission,setLogPermission] = useState(false)

  useEffect(() => {

    jwtGetFetcher(getJwt())('/api/customer-users/viva-settings/')
    .then((response) => {
      setIsVivaLite(response?.settings?.viva?.viva_lite === "true")
      window.isVivaLite = response?.settings?.viva?.viva_lite  === "true";
      setSsoEnabled(response?.settings?.viva?.ssoEnabled === "true")
      setPacsdbName(response?.pacsdbname)
    })
    .catch((error) => {
      setIsVivaLite(false)
      setSsoEnabled(false)
      setPacsdbName('')
      window.isVivaLite = false;
    })

    jwtGetFetcher(getJwt())('/api/customer-users?filter='+appAuthenticatedUser()+'&page=0&size=500&sort=login,asc')
      .then(
        response => {
          var user = response.users.filter(
            (user) => user.login === appAuthenticatedUser()
            )
          var userId = user[0].id
          if(userId){
            jwtGetFetcher(getJwt())(`/api/customer-users/${userId}`).then(
              data => {
                if(data?.worklistMenus?.auditLogs){
                  setLogPermission(true)
                }
              }
            )
          }
        }
      )
      .catch(
        (error) => {
          setLogPermission(false)
        }
      )


  }, [])

  const addOrUpdateUser = (newUser) => (
    newUser.id
      ? jwtPostFetcher(getJwt())('/api/customer-users', newUser, { method: 'PUT' }) // Update
      : jwtPostFetcher(getJwt())('/api/customer-users', newUser)
  )
    .then((user) =>
      setNotification(newUser.id ? "User updated successfully." : "User added successfully.")
    )

  return <ThemeProvider theme={StyledTheme}>
    <StartlibsProvider value={StartlibsConfig}>
      <>
        <DefaultStyle />
        <CheckScrollOnBottom />
        <ProviderErrorBoundary>
          <PageLoadingSuspense>
            <Router component={Identity}>
              <UserList default referrer={referrer} logPermission={logPermission} />
              <EditUser path="edit/:id" action={addOrUpdateUser} referrer={referrer}/>
              <EditUser path="new" action={addOrUpdateUser} referrer={referrer}/>
            </Router>
          </PageLoadingSuspense>
        </ProviderErrorBoundary>
        <Notifications />
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 100 }}>
          <Slot name="Popup" />
        </div>
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 200 }}>
          <Slot name="Portal" />
          <Slot name="Dialog" />
          <Slot name="Confirm-Dialog" />
          <Slot name="Notification" />
          <Slot name="Notifications" />
        </div>
        <div id="version" style={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 200}}>
          <span style={{display: 'none'}}>Running version: {process.env.V_TAG}</span>
          <span style={{display: 'none'}}>Referrer: {referrer}</span>
          {logPermission && <span style={{display: 'none'}}>Has Logs Permission </span>}
        </div>
      </>
    </StartlibsProvider>
  </ThemeProvider>
}

export default hot(App)
