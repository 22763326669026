import {
  AutoComplete,
  Combobox,
  Errors,
  Field,
  FormValue,
  RadioboxGroup,
  SimpleRadiobox,
  TextInput,
  ToggleCheckbox,
  WithForm
} from '@startlibs/form';
import {
  Button,
  ConfirmDialog,
  Dialog,
  Icon,
  SplitColumnsContainer,
  Tooltip,
  setNotification
} from '@startlibs/components';
import {callIfFunction, getColor, isMobile} from '@startlibs/utils';
import {navigate} from '@reach/router'
import { useToggle} from '@startlibs/core'
import React, { useRef } from 'react';
import _ from 'lodash/fp'
import styled, { css } from 'styled-components';

import {BoxPageContainer, BoxPageWrapper, BoxWrapperFooter, BoxWrapperHeading} from '../components/BoxPageLayout';
import {EditUserPage} from '../components/EditUserLayout';
import { InfoBox } from '../components/InfoBox';
import {PurviewFooter} from '../components/PurviewFooter';
import { UserCard } from './userlist/UserCard';
import {
  WORKLIST_MENUS_CUSTOM, WORKLIST_MENUS_RADIOLOGIST, WORKLIST_MENUS_RADIOLOGIST_TECHNICIAN, WORKLIST_MENUS_REFERRING_PHYSICIAN, WORKLIST_MENUS_SECRETARY, WORKLIST_MENUS_SYSTEM_ADMINISTRATOR,
  WORKLIST_OPTIONS_CUSTOM, WORKLIST_OPTIONS_RADIOLOGIST, WORKLIST_OPTIONS_RADIOLOGIST_TECHNICIAN, WORKLIST_OPTIONS_REFERRING_PHYSICIAN, WORKLIST_OPTIONS_SECRETARY, WORKLIST_OPTIONS_SYSTEM_ADMINISTRATOR } from '../utils/templatesWorklist';
import { WorklistToolsPopup } from '../components/WorklistToolsPopup';
import {buildValidation, confirmEmails, confirmHasFilters, emailValidation, filtersOnValidation, required, userTemplateValidation} from '../utils/validation';
import { getJwt } from '../hooks/useJwt';
import { getUsername } from '../hooks/useAuthenticatedUser';
import { jwtGetFetcher } from '../utils/authFetch';
import { lazyUserTemplate } from '../utils/lazyUserTemplate';
import { lazyUsers } from '../utils/lazyUser';
import {willUseSuspense} from '../hooks/useSuspense'
import { getIsVivaLite } from '../hooks/useIsVivaLite';
import { getSsoEnabled } from '../hooks/useSsoEnabled';

/*** Styled Components ***/

const DropdownDiv = styled.div`
  padding: 1rem;
  padding-right: 2.5rem;
  color: ${getColor('primary')};
  border-radius: 6px;
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  flex-shrink: 0;
  background: rgba(0,0,0,0.06);
  :hover {
    background: rgba(41, 122, 163, 0.175);
  }
  .clickable {
    cursor: pointer;
  }
  ${Icon} {
    position: absolute;
    right: 1rem;
    transform: translateY(-50%);
    top: 50%;
    font-size: 14px;
  }
`

const RestrictionsWrapper = styled.div`
  background-color: ${getColor('gray240')};
  border-radius: 6px;
  padding: .5rem 1rem;
  min-height: 36px;
  .restriction-heading {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    span {
      flex-basis: 0;
      flex-grow: 1;
      margin: .5rem 0;
    }
    a.link {
      flex-shrink: 0;
    }
  }
  .restriction-category {
    margin-top: .75rem;
    :last-of-type {
      margin-bottom: .75rem;
    }
  }
  .restricted-text {
    color: ${getColor('gray150')};
  }
  ${props => props.filtersOn == NOT_SELECTED && css`
    display: none;
  `}
  ${props => props.filtersOn == ONLY_ASSIGNED && css`
    display: none;
  `}
  ${props => props.filtersOn == ALL_STUDIES && css`
    display: none;
  `}

`

export const SpecialCheckbox = styled(ToggleCheckbox)`
  ${props => props.disabled && css`
    opacity: 0.5;
    border: 1px solid ${getColor('gray210')};
    input {
      display: none;
    }
  `}
`
/************************/

// Matches Usertemplate from worklistMenus and worklistOptions
const checkMapOptionsToTemplate = (worklistMenus, worklistOptions) => {
  if (_.isEqual(worklistMenus, WORKLIST_MENUS_SYSTEM_ADMINISTRATOR)
    && _.isEqual(worklistOptions, WORKLIST_OPTIONS_SYSTEM_ADMINISTRATOR())) {
    return 5
  }
  if (_.isEqual(worklistMenus, WORKLIST_MENUS_RADIOLOGIST)
    && _.isEqual(worklistOptions, WORKLIST_OPTIONS_RADIOLOGIST())) {
    return 4
  }
  if (_.isEqual(worklistMenus, WORKLIST_MENUS_RADIOLOGIST_TECHNICIAN)
    && _.isEqual(worklistOptions, WORKLIST_OPTIONS_RADIOLOGIST_TECHNICIAN())) {
    return 3
  }
  if (_.isEqual(worklistMenus, WORKLIST_MENUS_REFERRING_PHYSICIAN)
    && _.isEqual(worklistOptions, WORKLIST_OPTIONS_REFERRING_PHYSICIAN())) {
    return 2
  }
  if (_.isEqual(worklistMenus, WORKLIST_MENUS_SECRETARY)
    && _.isEqual(worklistOptions, WORKLIST_OPTIONS_SECRETARY())) {
    return 1
  }
  return false
}

// Map WorklistOptions Json (tri-state){-1 or 2: disabled and invisible, 0: enabled and invisible, 1: enabled and visible} to boolean (true/false)
const originalValueToBoolean = (worklistOptions, accessLevelId = 0) => {
  return {
    viewStudy:          worklistOptions.viewStudy         == 0 || worklistOptions.viewStudy         == 1 ? true : false,
    viewerLink:         worklistOptions.viewerLink        == 0 || worklistOptions.viewerLink        == 1 ? true : false,
    launchInHoros:      worklistOptions.launchInHoros     == 0 || worklistOptions.launchInHoros     == 1 ? true : false,
    downloadStudy:      worklistOptions.downloadStudy     == 0 || worklistOptions.downloadStudy     == 1 ? true : false,
    downloadCD:         worklistOptions.downloadCD        == 0 || worklistOptions.downloadCD        == 1 ? true : false,
    attachNonDicom:     worklistOptions.attachNonDicom    == 0 || worklistOptions.attachNonDicom    == 1 ? true : false,
    studyNotes:         worklistOptions.studyNotes        == 0 || worklistOptions.studyNotes        == 1 ? true : false,
    report:             worklistOptions.report            == 0 || worklistOptions.report            == 1 ? true : false,
    reportWriteAccess:  worklistOptions.reportWriteAccess
    ? worklistOptions.reportWriteAccess == 1 ? true : false 
    : worklistOptions?.reportWriteAccess == false 
      ? false : worklistOptions.report == 0 || worklistOptions.report == 1 
        ? accessLevelId >= 4 ? true : false 
        : false,
    // reportWriteAccess:  worklistOptions.reportWriteAccess ? worklistOptions.reportWriteAccess == 0 || worklistOptions.reportWriteAccess == 1 ? true : false : worklistOptions.report            == 0 || worklistOptions.report            == 1 ? true : false,
    share:              worklistOptions.share             == 0 || worklistOptions.share             == 1 ? true : false,
    markAsRead:         worklistOptions.markAsRead        == 0 || worklistOptions.markAsRead        == 1 ? true : false,
    activityLogs:       worklistOptions.activityLogs      == 0 || worklistOptions.activityLogs      == 1 ? true : false,
  }
}

/* Set WorklistOptions Json values: (tri-state){-1 or 2: disabled and invisible, 0: enabled and invisible, 1: enabled and visible} from booleanValues.
  It is only possible to enabled/disable the options.
  The user itself can change visibility (0 invisible / 1 visible) in 'Viva'
*/
const setWorklistOptionsValuesFromBoolean = (boolean,originalValues) => {
  return {
    viewStudy:          boolean.viewStudy         ? ((originalValues.viewStudy == 1 || originalValues.viewStudy == 0)    ? originalValues.viewStudy : 1) : -1,
    viewerLink:         boolean.viewerLink        ? ((originalValues.viewerLink == 1 || originalValues.viewerLink == 0) ? originalValues.viewerLink : 1) : -1,
    launchInHoros:      boolean.launchInHoros     ? ((originalValues.launchInHoros == 1 || originalValues.launchInHoros == 0) ? originalValues.launchInHoros : 1) : -1,
    downloadStudy:      boolean.downloadStudy     ? ((originalValues.downloadStudy == 1 || originalValues.downloadStudy == 0) ? originalValues.downloadStudy : 1) : -1,
    downloadCD:         boolean.downloadCD        ? ((originalValues.downloadCD == 1 || originalValues.downloadCD == 0) ? originalValues.downloadCD : 1) : -1,
    attachNonDicom:     boolean.attachNonDicom    ? ((originalValues.attachNonDicom == 1 || originalValues.attachNonDicom == 0) ? originalValues.attachNonDicom : 1) : -1,
    studyNotes:         boolean.studyNotes        ? ((originalValues.studyNotes == 1 || originalValues.studyNotes == 0) ? originalValues.studyNotes : 1) : -1,
    report:             boolean.report            ? ((originalValues.report == 1 || originalValues.report == 0) ? originalValues.report : 1) : -1,
    // reportWriteAccess:  boolean.reportWriteAccess ? ((originalValues.reportWriteAccess == 1 || originalValues.reportWriteAccess == 0) ? originalValues.reportWriteAccess : 1) : -1,
    reportWriteAccess:  boolean.reportWriteAccess ? 1 : 0,
    share:              boolean.share             ? ((originalValues.share == 1 || originalValues.share == 0) ? originalValues.share : 1) : -1,
    markAsRead:         boolean.markAsRead        ? ((originalValues.markAsRead == 1 || originalValues.markAsRead == 0) ? originalValues.markAsRead : 1) : -1,
    activityLogs:       boolean.activityLogs      ? ((originalValues.activityLogs == 1 || originalValues.activityLogs == 0) ? originalValues.activityLogs : 1) : -1,
  }
}
const NOT_SELECTED  = -1;
const ONLY_ASSIGNED = 0;
const FILTERED      = 1;
const ALL_STUDIES   = 2;

const setStudyAccess = (worklistFilters) => 
  worklistFilters?.filtersOn == true
    ? worklistFilters?.filters?.length > 0
      ? FILTERED : ONLY_ASSIGNED
    : worklistFilters?.filtersOn == false
      ? ALL_STUDIES : NOT_SELECTED


const TYPE_PHYSICIAN = "refPhys"
const TYPE_INSTITUTION = "institution"
const DEFAULT_CASES_OPTIONS_LABELS = { [1]: "All cases", [2]: "Open cases", [3]: "Reported cases" }
const DEFAULT_CASES_OPTIONS = Object.keys(DEFAULT_CASES_OPTIONS_LABELS)
const getEmptyUser = (isVivaLite) => {

  return {
    defaultCases: 2,
    worklistMenus: { 
      studyBrowser: true,
      createBlankStudy: true,
      reports: false,
      upload: true,
      userManagement: false,
      auditLogs: false
    },
    worklistOptions: { viewStudy: isVivaLite ? -1 : 1, viewerLink: isVivaLite ? -1 : 1, launchInHoros: 1, downloadStudy: 1, downloadCD: 1, attachNonDicom: 1, studyNotes: 1, report: 1, reportWriteAccess: 0, share: 1, markAsRead: 1 },
    worklistOptionsBoolean: originalValueToBoolean({ viewStudy: isVivaLite ? -1 : 1, viewerLink: isVivaLite ? -1 : 1, launchInHoros: 1, downloadStudy: 1, downloadCD: 1, attachNonDicom: 1, studyNotes: 1, report: 1, reportWriteAccess: 0, share: 1, markAsRead: 1 }),
    worklistFilters: { filters: [], filtersOn: -1 },
    physicians: [],
    institutions: []
  }
};

// Adds front-end fields as confirmEmail, WorklistOptions Json as true/false, physicians and instituions filters, etc...
const addUserOptions = (user, brandedUrl = '') => {

  var vivaLiteWorklistOptions = _.flow(
    _.set('viewStudy',-1),
    _.set('viewerLink',-1)
  )(user.worklistOptions)
  
    return {
      ...user,
      confirmEmail: user.email,
      worklistOptionsBoolean: originalValueToBoolean(window.isVivaLite ? vivaLiteWorklistOptions : user.worklistOptions, user.accessLevelId),
      physicians: setFilters(user.worklistFilters ? user.worklistFilters.filters : [], TYPE_PHYSICIAN),
      institutions: setFilters(user.worklistFilters ? user.worklistFilters.filters : [], TYPE_INSTITUTION),
      worklistFilters: {filters: user?.worklistFilters?.filters !== undefined ? user.worklistFilters.filters.concat([]) : []},
      filtersOn: setStudyAccess(user?.worklistFilters),
      defaultCases: user.worklistOptions.tabCasesDefault ? user.worklistOptions.tabCasesDefault : 2,
      lastYearFilter: user.worklistOptions.lastYearFilter !== undefined ? user.worklistOptions.lastYearFilter : 1,
      orderBy: user?.worklistFilters?.orderBy !== undefined ? user.worklistOptions.orderBy : 0,
      worklistOptions: window.isVivaLite ? _.unset('tabCasesDefault', user.vivaLiteWorklistOptions) : _.unset('tabCasesDefault', user.worklistOptions),
      worklistMenus: _.unset('changePassword', user.worklistMenus),
      brandedUrl: brandedUrl
    }
}

const useUserSuspense = willUseSuspense((id) =>
  (!id && !_.isString(id))
    ? Promise.resolve()
    : jwtGetFetcher(getJwt())(`/api/customer-users/${id}`)
)

const transformUser = ({ ...values }) => {
  
  // This ensures that no user can have activity log worklist tool marked as true if the auditlogs menu is set to false. 
  if(values.worklistMenus.auditLogs == false && values.worklistOptionsBoolean.activityLogs == true){
    values.worklistOptionsBoolean.activityLogs = false
  }

  var result = _.flow(
    _.set('worklistOptions', setWorklistOptionsValuesFromBoolean(values.worklistOptionsBoolean, values.worklistOptions)),
    _.set('worklistOptions.tabCasesDefault', Number(values.defaultCases)),
    _.set('worklistOptions.lastYearFilter', Number(values.lastYearFilter)),
    _.set('worklistOptions.orderBy', Number(values.orderBy)),
    _.unset('lastYearFilter'),
    _.unset('orderBy'),
    _.set('worklistFilters.filtersOn', (values.filtersOn == ONLY_ASSIGNED || values.filtersOn == FILTERED) ? true : false),
    _.set('worklistFilters.filters', (values.filtersOn == ONLY_ASSIGNED && values?.worklistFilters?.filters?.length > 0) ? [] : doubleToSimpleQuotesFilters(values?.worklistFilters?.filters))
    
  )(values)

  if (values.id > 0) {
    return _.flow(
      _.unset('firstName'),
      _.unset('lastName'),
      _.unset('email'),
      _.unset('confirmEmail'),
      _.unset('login'),
      _.unset('brandedUrl')
    )(result)
  } else {
    return result
  }
}

const setFilters = (filters, key) => {
  
  return simpleToDoubleQuotes(_.map(_.iteratee('value'), _.filter(item => item.type == key, filters)))

}

const isExactMatch = (term) => {
  return (
    (term.charAt(0) == '\'') && (term.charAt(term.length-1) == '\'')
    || (term.charAt(0) == '\"') && (term.charAt(term.length-1) == '\"')
  )
}

const simpleToDoubleQuotes = (list) => {

  list.map((item, index) => {
    if((item.charAt(0) == '\'') && (item.charAt(item.length-1) == '\'')) {
      var changedQuotes = item.replaceAll("\'","\"")
      list[index] = changedQuotes
    }
  })

  return list

}

const addClosingDoubleQuoutes = (list) => {

  list.map((item, index) => {
    if (
      (item.charAt(0) == '\'') && (item.charAt(item.length - 1) !== '\'')
    ) {
      var changedQuotes = item + '\''
      list[index] = changedQuotes
    } else if (
      (item.charAt(0) == '"') && (item.charAt(item.length - 1) !== '"')
    ) {
      var changedQuotes = item + '"'
      list[index] = changedQuotes
    }
  })

  return list

}

const doubleToSimpleQuotesFilters = (list) => {
  
  list.map((item, index) => {
    if((item.value.charAt(0) == '\"') && (item.value.charAt(item.value.length-1) == '\"')) {
      var changedQuotes = item.value.replaceAll("\"","\'")
      list[index].value = changedQuotes
    }
  })

  return list

}

const clearRestrictions = (form) => {
  form.setValues({ institutions: [], physicians: [] });
}

const preValidation = buildValidation({
  firstName: required,
  lastName: required,
  login: required,
  email: [required, emailValidation, confirmEmails('confirmEmail')],
  confirmEmail: required,
  userTemplate: [required, userTemplateValidation],
  filtersOn: [filtersOnValidation, confirmHasFilters('worklistFilters.filters')]
})

var authUser = ''
getUsername().then((response) => {
  authUser = response
})

export const EditUser = ({
  action,
  id,
  cancelDefaultUrl = "/",
  location,
  onSuccess = () => {navigate(location.state?.nextLocation || ("/"));},
  footer,
  isIncomplete,
  referrer
}) => {

  const USER_TEMPLATE_OPTIONS_LABELS = lazyUserTemplate.read()
  const USER_TEMPLATE_OPTIONS = Object.keys(USER_TEMPLATE_OPTIONS_LABELS)
  const user = id > 0 ? addUserOptions(useUserSuspense(id)) : addUserOptions(getEmptyUser(window.isVivaLite),  referrer)
  const formRef = useRef()
  const worklistTools = useToggle()
  const filtersDialog = useToggle();
  const confirmNoFiltersDialog = useToggle();
  const confirmRemoveFiltersDialog = useToggle();

  const goToUserlist = (e) => {
    navigate("/")
  }

  const isAuthenticatedUser = () => user.id > 0 ? authUser == user.login : false;
  const hasFilter = (filters) => {

    var physicians = _.map(_.iteratee('value'), _.filter(physician => physician.type == TYPE_PHYSICIAN, filters));
    var institutions = _.map(_.iteratee('value'), _.filter(institution => institution.type == TYPE_INSTITUTION, filters));

    return institutions.length > 0 || physicians.length > 0 ? true : false
  }

  // Set Physicians (refPhys) and/or Institutions (institutions) filters
  const getFiltersAsArrayForDialog = (userFilters) => {
    var filters = {}
    var physicians = simpleToDoubleQuotes(_.map(_.iteratee('value'), _.filter(physician => physician.type == TYPE_PHYSICIAN, userFilters)));
    var institutions = simpleToDoubleQuotes(_.map(_.iteratee('value'), _.filter(institution => institution.type == TYPE_INSTITUTION, userFilters)));

    filters =
      _.flow(
        _.set("physicians", physicians),
        _.set("institutions", institutions)
      )(filters)
    return filters
  }

  const setFormattedWorklistFilters = (array, type) => {

    // set the arrays in the current structure:
    // Create and remove tags in the format:
    // [
    //   {type:"refPhys",value:"Test 1"},
    //   {type:"refPhys",value:"Test 2"},
    //   {type:"institution",value:"CITYU"},
    //   {type:"institution",value:"Hospital Unimed"},
    //   {type:"patientID",value:"SC20"}
    // ]

    var worklistFormattedFilters = formRef.current.getValue('worklistFilters.filters');

    // Adding new filters
    array.map(item => {
      let newItem = { type: type, value: item }
      if (_.find({ type: type, value: item }, worklistFormattedFilters) == undefined) {
        worklistFormattedFilters = worklistFormattedFilters.concat(newItem) // concat to create neew object
      }
    });

    // Removing filters
    worklistFormattedFilters = worklistFormattedFilters.filter(existingItem => {
      if (existingItem.type == type) {
        if (array.findIndex((item) => item == existingItem.value) < 0) {
          return false
        }
      }
      return true
    })

    // Set the value
    formRef.current.setValue('worklistFilters.filters',
      worklistFormattedFilters
    )
  }

  const setWorklistFilters = (physicians, institutions) => {
    formRef.current.setValue("physicians", physicians);
    formRef.current.setValue("institutions", institutions);
    setFormattedWorklistFilters(physicians, TYPE_PHYSICIAN)
    setFormattedWorklistFilters(institutions, TYPE_INSTITUTION)
  }

  const setUserTemplate = (option = '') => {
    

    // This changes activityLogs worklistOptions tool. 
    // If audit logs menu is enabled , user can have the activity logs shortcut on the worklist
    if(option == 'auditLogs'){
        let newValues = formRef.current.getValue('worklistOptionsBoolean')
        newValues.activityLogs = formRef.current.getValue('worklistMenus').auditLogs
        formRef.current.setValue('worklistOptionsBoolean',newValues)
    }
    
    // If report read is not allowed, report write wont be allowed either
    if(option == 'report'){
      let newValues = formRef.current.getValue('worklistOptionsBoolean')
      if(newValues.report === false){
        newValues.reportWriteAccess = newValues.report
        formRef.current.setValue('worklistOptionsBoolean',newValues)
      }
    }

    if (formRef.current.getValue('userTemplate') !== 6) { // Custom
      formRef.current.setValue("userTemplate", 6);
    } else {
      // Mapping options and check if it matches one default template
      var template = checkMapOptionsToTemplate(formRef.current.getValue('worklistMenus'), formRef.current.getValue('worklistOptionsBoolean'))
      template ? formRef.current.setValue("userTemplate", template) : null
    }
  }

  const handleUserTemplateChanges = (template) => {
    formRef.current.setValue("userTemplate", template);
    setWorklistMenusBasedOnTemplate(template);
    setWorklistOptionsBasedOnTemplate(template);
    if (isAuthenticatedUser()) {
      formRef.current.setValue("worklistMenus", _.set('userManagement', true, formRef.current.getValues("worklistMenus")))
      if (template != 5) {
        formRef.current.setValue("userTemplate", 6);
        setNotification('User management privileges preserved. This prevents you from losing access to this portal.')
      }
    }
  }

  const handleUsernameChange = (username) => {
    formRef.current.setValue("login", username.toLowerCase());
  }

  const setWorklistMenusBasedOnTemplate = (template) => {
    switch (template) {
      case '6':
        formRef.current.setValue("worklistMenus", WORKLIST_MENUS_CUSTOM)
        break;
      case '5':
        formRef.current.setValue("worklistMenus", WORKLIST_MENUS_SYSTEM_ADMINISTRATOR)
        break;
      case '4':
        formRef.current.setValue("worklistMenus", WORKLIST_MENUS_RADIOLOGIST)
        break;
      case '3':
        formRef.current.setValue("worklistMenus", WORKLIST_MENUS_RADIOLOGIST_TECHNICIAN)
        break;
      case '2':
        formRef.current.setValue("worklistMenus", WORKLIST_MENUS_REFERRING_PHYSICIAN)
        break;
      case '1':
        formRef.current.setValue("worklistMenus", WORKLIST_MENUS_SECRETARY)
        break;
    }
  }

  const setWorklistOptionsBasedOnTemplate = (template) => {
    switch (template) {
      case '6':
        formRef.current.setValue("worklistOptionsBoolean", WORKLIST_OPTIONS_CUSTOM());
        break;
      case '5':
        formRef.current.setValue("worklistOptionsBoolean", WORKLIST_OPTIONS_SYSTEM_ADMINISTRATOR());
        break;
      case '4':
        formRef.current.setValue("worklistOptionsBoolean", WORKLIST_OPTIONS_RADIOLOGIST());
        break;
      case '3':
        formRef.current.setValue("worklistOptionsBoolean", WORKLIST_OPTIONS_RADIOLOGIST_TECHNICIAN());
        break;
      case '2':
        formRef.current.setValue("worklistOptionsBoolean", WORKLIST_OPTIONS_REFERRING_PHYSICIAN());
        break;
      case '1':
        formRef.current.setValue("worklistOptionsBoolean", WORKLIST_OPTIONS_SECRETARY());
        break;
    }
  }

  const onSubmit = (values) => {
    return action(values)
      .then((user) => {
        return user
      })
  }

  const onFailure = (e, [resp, { status, title, detail }], form) => {

    if(resp.errorKey === 'userexists')
      return form.setError("login", "There is an already registered user with the provided username.")

    if (status === 400)
      return  form.setError("submit", "You cannot remove your own access to this module. This prevents you from losing access to this portal.")

    if (status === 500)
      return form.setError("submit", "Sorry! There was an unexpected error.")

  }

  const userFromForm = () =>{

    var newUser = {
      firstName: formRef.current.getValue('firstName'),
      lastName: formRef.current.getValue('lastName'),
      userTemplate: formRef.current.getValue('userTemplate'),
      email:  formRef.current.getValue('email'),
      institution: formRef.current.getValue('institution')
    }
    return newUser
  }

  const submit = () =>{
    var filtersOn = formRef.current.getValue('filtersOn');
    var hasFilters = hasFilter(formRef.current.getValue('worklistFilters.filters'));

    if(filtersOn == ONLY_ASSIGNED && hasFilters){
      confirmRemoveFiltersDialog.open()
    }else{
      formRef.current.willSubmitForm()
    }

  }

  return <WithForm
    alwaysSave
    onFailure={onFailure}
    onSuccess={(values) => {
      lazyUsers.refresh()
      onSuccess(values)
    }}
    ref={formRef}
    preValidation={preValidation}
    action={onSubmit}
    transform={transformUser}
    values={user}>{form =>
      <EditUserPage>
        <BoxPageContainer>
          <BoxPageWrapper>
            <BoxWrapperHeading>
              <h2><Icon icon="user" />
                {
                  isIncomplete
                    ? ' Please complete your profile'
                    : user.id > 0
                      ? <span>{' User: ' + user.login}</span>
                      : ' Add new user'
                }
              </h2>
              <Button onClick={goToUserlist}> View all users </Button>
            </BoxWrapperHeading>
            <p css="font-size:14px;">Please provide the user details and granted permissions.</p>
            <SplitColumnsContainer viewportMinWidth={850} className="content">
              <div className="form">
                <div className="fields-wrapper">
                  <SplitColumnsContainer viewportMinWidth={980}>
                    <TextInput
                      readOnly={form.isLoading}
                      autoFocus
                      label="First name:"
                      path="firstName"
                      placeholder="John"
                      mandatory
                      disabled={user.id ? true : false}
                    />
                    <TextInput
                      readOnly={form.isLoading}
                      label="Last name:"
                      path="lastName"
                      placeholder="Doe"
                      mandatory
                      disabled={user.id ? true : false}
                    />
                  </SplitColumnsContainer>
                  <SplitColumnsContainer viewportMinWidth={980}>
                    <TextInput
                      readOnly={form.isLoading}
                      label="Username:"
                      path="login"
                      placeholder="Ex: your@email.com"
                      mandatory
                      disabled={user.id ? true : false || getSsoEnabled()}
                      onChange={handleUsernameChange}
                      // className="username-field"
                    />
                    <TextInput
                      readOnly={form.isLoading}
                      label="Institution:"
                      path="institution"
                      placeholder="Ex: purview"
                      // mandatory
                      // disabled={user.id ? true : false}
                      // className="username-field"
                    />
                  </SplitColumnsContainer>
                  <SplitColumnsContainer viewportMinWidth={980}>
                    <TextInput
                      readOnly={form.isLoading}
                      label="Email:"
                      path="email"
                      placeholder="user@email.com"
                      mandatory
                      disabled={user.id ? true : false}
                      onChange={getSsoEnabled() && handleUsernameChange}
                    />
                    <TextInput
                      readOnly={form.isLoading}
                      label="Confirm email:"
                      path="confirmEmail"
                      placeholder="user@email.com"
                      mandatory
                      disabled={user.id ? true : false}
                    />
                  </SplitColumnsContainer>
                </div>

                <div className="study-access-wrapper">
                  <h3>Study access</h3>
                  {user.id && user.firstEdition && !user.useLegacyFilters ?
                    <InfoBox lightYellow withIcon>
                      <Icon icon="warning" />
                      <div><strong>Warning:</strong> This user currently has legacy filters in the database. Add or update filters in the widget below and save the profile to overwrite.</div>
                    </InfoBox>
                    : null
                  }
                  {user.useLegacyFilters
                    ?
                    <InfoBox lightBlue withIcon>
                      <Icon icon="warning" />
                      <div><strong>Warning:</strong> This user currently has legacy filters in the database. Please call (800) 501-1537 or e-mail <a className="link" href="mailto:support@purview.net">support@purview.net</a> to update the filters.</div>
                    </InfoBox>
                    :
                    <>
                      <p css="font-size:14px;">Allow this user to access:</p>
                      <FormValue path='worklistFilters.filters'>
                        {filters =>
                          <RadioboxGroup path="filtersOn" framedBoxes horizontal wide css="margin-bottom:1rem;">
                            <SimpleRadiobox
                              fieldValue={0}
                              label="Assigned studies only"
                            />
                            <SimpleRadiobox
                              fieldValue={1}
                              label="Filtered studies"
                            />
                            <SimpleRadiobox
                              fieldValue={2}
                              label="All studies"
                            />
                          </RadioboxGroup>
                        }
                      </FormValue>  
                    </>
                  }
                  <FormValue path='filtersOn'>
                    {filtersOn =>
                      <FormValue path='worklistFilters.filters'>
                        {filters =>
                          <>
                            <RestrictionsWrapper filtersOn={filtersOn}>
                              {hasFilter(filters)
                                ?
                                <>
                                  <div className="restriction-heading">
                                    <span>This user has access to studies containing any of the following:</span>
                                    <a className="link" onClick={filtersDialog.open} disabled={user.useLegacyFilters}>
                                      Edit filters
                                    </a>
                                  </div>
                                  <hr />
                                  <div className="restriction-category">
                                    <strong>Referring physician: </strong>
                                    <span className="restricted-text">
                                      <FormValue path='physicians'>{
                                        physicians =>
                                          physicians?.map(
                                            (item, index) => index == 0 ? item : ", " + item
                                          )
                                      }
                                      </FormValue>
                                    </span>
                                  </div>
                                  <div className="restriction-category">
                                    <strong>Institution: </strong>
                                    <span className="restricted-text">
                                      <FormValue path='institutions'>{
                                        institutions =>
                                          institutions?.map(
                                            (item, index) => index == 0 ? item : ", " + item
                                          )
                                      }
                                      </FormValue>
                                    </span>
                                  </div>
                                </>
                                :
                                <>
                                  <div className="restriction-heading">
                                    <span><strong>Specify from which institutions or referring physicians this user will have access to the studies.</strong></span>
                                    {user.useLegacyFilters ?
                                      <Tooltip content="Adding filters locked for this user. Contact support.">
                                        <a className="link" onClick={filtersDialog.open} disabled={user.useLegacyFilters}>
                                          Add filters
                                        </a>
                                      </Tooltip>
                                      :
                                      <a className="link" onClick={filtersDialog.open} disabled={user.useLegacyFilters}>
                                        Add filters
                                      </a>
                                    }
                                  </div>
                                </>
                              }
                            </RestrictionsWrapper>
                            {filtersOn == 0 && !user.useLegacyFilters &&
                              <p>This user will not have access to any study, except to those studies that are assigned to them.</p>
                            }
                          </>
                        }
                      </FormValue>
                    }
                  </FormValue>
                </div>
              </div>
              <div className="form-right">
                <div className="fields-wrapper">

                  <SplitColumnsContainer viewportMinWidth={980}>
                    <Field label="User template:" mandatory>
                      <Combobox path="userTemplate" mandatory required placeholder="Select user type" options={USER_TEMPLATE_OPTIONS} getLabel={v => USER_TEMPLATE_OPTIONS_LABELS[v]} onChange={handleUserTemplateChanges} />
                    </Field>
                    <Field label="Default cases tab:">
                      <Combobox path="defaultCases" options={DEFAULT_CASES_OPTIONS} getLabel={v => DEFAULT_CASES_OPTIONS_LABELS[v]} />
                    </Field>
                  </SplitColumnsContainer>
                  <div css="margin-bottom: 1rem;">
                    <span>Grant this user access to:</span>
                  </div>
                  <SplitColumnsContainer viewportMinWidth={980}>
                    <ToggleCheckbox
                      label={<strong>Study browser</strong>}
                      path="worklistMenus.studyBrowser"
                      onChange={() => setUserTemplate()}
                    />
                    <ToggleCheckbox
                      label={<strong>Creating a blank study</strong>}
                      path="worklistMenus.createBlankStudy"
                      onChange={() => setUserTemplate()}
                    />
                  </SplitColumnsContainer>
                  <SplitColumnsContainer viewportMinWidth={980}>
                    <ToggleCheckbox
                      label={<strong>Analytics</strong>}
                      path="worklistMenus.reports"
                      onChange={() => setUserTemplate()}
                    />
                    <ToggleCheckbox
                      label={<strong>Upload</strong>}
                      path="worklistMenus.upload"
                      onChange={() => setUserTemplate()}
                    />
                  </SplitColumnsContainer>
                  <SplitColumnsContainer className="tooltip-container" viewportMinWidth={980}>
                    <Tooltip content={authUser == user.login && "You cannot remove your own access to this module."}>
                      <SpecialCheckbox
                        label={<strong>User management module</strong>}
                        path="worklistMenus.userManagement"
                        onChange={() => setUserTemplate()}
                        disabled={authUser == user.login}
                      />
                    </Tooltip>
                    <ToggleCheckbox
                      label={<strong>Activity logs</strong>}
                      path="worklistMenus.auditLogs"
                      onChange={() => setUserTemplate('auditLogs')}
                    />
                  </SplitColumnsContainer>

                  <DropdownDiv onClick={worklistTools.open}>
                    <div className="clickable">
                      <b>Worklist tools:</b> View, Download, Download for CD, Share, Attachments, Study notes, Reports, Launch in Horos, Mark as read, Get webviewer link
                    <Icon icon="arrow-down" />
                    </div>
                    {
                      worklistTools.isOpen &&
                      <WorklistToolsPopup onCollision={isMobile() ? "fit flip" : undefined} closePopup={worklistTools.close}
                        values={formRef.current.getValue("worklistOptionsBoolean")} setUserTemplate={setUserTemplate}>

                      </WorklistToolsPopup>
                    }
                  </DropdownDiv>
                </div>
              </div>
            </SplitColumnsContainer>

            {filtersDialog.isOpen &&
              <FiltersDialog
                closeDialog={filtersDialog.close}
                filtersAsArray={() => getFiltersAsArrayForDialog(formRef.current.getValue('worklistFilters.filters'))}
                setWorklistFilters={setWorklistFilters} />
            }

            <Errors />

            <BoxWrapperFooter>
              {
                callIfFunction(footer, form) || <>
                  {
                    user &&
                    <div className="left-block">

                    </div>
                  }
                  <div className="right-block">
                    <Button onClick={() => form.confirm(user.id ? "discard-user-edit" : "discard-user-create").then(() => {navigate(cancelDefaultUrl);})}>Cancel</Button>
                     {/* <Button isLoading={form.isLoading} highlight type="submit">{user ? "Save changes" : "Add user"}</Button> */}
                     <Button
                      isLoading={form.isLoading}
                      highlight
                      onClick={() => submit()}
                    >{user.id ? "Save changes" : "Add user"}</Button>
                  </div>
                </>
              }
            </BoxWrapperFooter>
            {confirmNoFiltersDialog.isOpen &&
              <ConfirmNoFiltersDialog submit={form.willSubmitForm} closeDialog={confirmNoFiltersDialog.close} isLoading={form.isLoading} user={userFromForm()} />
            }
            {confirmRemoveFiltersDialog.isOpen &&
              <ConfirmRemoveFiltersDialog submit={form.willSubmitForm} closeDialog={confirmRemoveFiltersDialog.close} isLoading={form.isLoading} user={userFromForm()} />
            }
          </BoxPageWrapper>
        </BoxPageContainer>
        <PurviewFooter />
      </EditUserPage>
    }</WithForm>
}

const ConfirmExitDialog = ({ discard, keep, closeDialog }) => {

  return <ConfirmDialog
    title="Confirm changes made"
    closeDialog={closeDialog}
    onConfirm={keep}
    footer={<>
      <Button hover="alert" onClick={discard}>Discard changes</Button>
      <Button autoFocus success onClick={keep}>Keep changes</Button>
    </>}
  >
    <p>You are about to close without saving.</p>
    <p>Would you like to keep the changes made in the filters or to discard them?</p>
  </ConfirmDialog>
}

const ConfirmNoFiltersDialog = ({ submit, closeDialog, isLoading, user}) => {
  return <ConfirmDialog
            css={`max-width: 50rem;`}
            title="Study access confirmation"
            closeDialog={closeDialog}
            onConfirm={submit}
            footer={<>
              <Button onClick={closeDialog}>Review study access</Button>
              <Button autoFocus highlight isLoading={isLoading} onClick={submit}>Proceed anyway</Button>
            </>}
          >
            <p>This user will not have access to any study, except to those studies assigned to them, as of now:</p>
            <UserCard user={user} />
            <p>Are you sure you want to proceed?</p>
          </ConfirmDialog>
}

const ConfirmRemoveFiltersDialog = ({ submit, closeDialog, isLoading, user}) => {
  return <ConfirmDialog
            css={`max-width: 50rem;`}
            title="Study access confirmation"
            closeDialog={closeDialog}
            onConfirm={submit}
            footer={<>
              <Button onClick={closeDialog}>Review study access</Button>
              <Button autoFocus highlight isLoading={isLoading} onClick={submit}>Proceed anyway</Button>
            </>}
          >
            <p>By setting study access as <i>"Assigned studies only"</i>, any previous added filter terms will be removed.</p>
            <p>Are you sure you want to proceed?</p>
          </ConfirmDialog>
}

const FiltersDialog = ({closeDialog, filtersAsArray, setWorklistFilters}) => {

  const autoCompleteRef = useRef()
  const confirmExitDialog = useToggle();
  const ASSIGNED_MSG = ' Any studies directly assigned to this user from the assignment worklist feature will also be displayed.'

  const didValuesChanged = (values,form) => {
    return _.isEqual(values,form.getValues()) ? false : true
  }

  const formatedList = (list, addQoute = true) =>{
    return list?.map(
      (item, index) => addQoute 
        ? index == 0 ? "\""+item+"\" " : "OR \"" + item+"\" " 
        : index == 0 ? item+" " : "OR " + item+" "
    )
  }

  return <>
    <WithForm
      action={(e) => setWorklistFilters(e.physicians, e.institutions)}
      values={filtersAsArray}
      onSuccess={closeDialog}
      ref={autoCompleteRef}
    >{form =>
      <Dialog
        withFormWrap
        onConfirm={form.willSubmitForm}
        closeDialog={didValuesChanged(filtersAsArray, form) && confirmExitDialog.open || closeDialog}
        title="Edit study access filters"
        footer={<>
          <Button onClick={() => clearRestrictions(form)} tabIndex={2}>Clear filters</Button>
          <Button onClick={closeDialog} tabIndex={2}>Cancel</Button>
          <Button highlight type="submit" isLoading={form.isLoading} onClick={form.willSubmitForm}>Update filters</Button>
        </>}
      >
        <p>Show only studies containing <strong>any of the following:</strong></p>
        <AutoComplete
          readOnly={form.isLoading}
          ref={autoCompleteRef}
          label="Referring Physician(s):"
          confirmIfValueKeys={['Tab']}
          path="physicians"
          bellowDescText='Use comma, enter or tab to add multiple. Use double quotes (" ") to match exact term. Use an exact match filter if you are granting study access to users with similar Referring Physician or Institution names.'
          className="specializations-input"
        />
        <AutoComplete
          readOnly={form.isLoading}
          ref={autoCompleteRef}
          label="Institution(s):"
          confirmIfValueKeys={['Tab']}
          path="institutions"
          bellowDescText='Use comma, enter or tab to add multiple. Use double quotes (" ") to match exact term. Use an exact match filter if you are granting study access to users with similar Referring Physician or Institution names.'
          className="specializations-input"
        />
        <FormValue path='physicians'>
          {physicians =>
          <FormValue path='institutions'>
            {institutions => {
              simpleToDoubleQuotes(addClosingDoubleQuoutes(physicians))
              simpleToDoubleQuotes(addClosingDoubleQuoutes(institutions))
              return physicians.length > 0 && institutions.length > 0
                ?
                  // All studies that include [“jones”] as Referring Physician AND all studies that include [“yale”] as institution will display. Any studies directly assigned to this user from the Assignment worklist feature will also be displayed.
                  <InfoBox lightBlue>
                    {(physicians.filter(item => !isExactMatch(item)).length > 0 && institutions.filter(item => !isExactMatch(item)).length > 0) 
                      ? <>
                          All studies that include <strong>[{formatedList(physicians.filter(item => !isExactMatch(item)))}]</strong> as Referring Physician
                          <strong> AND</strong> all studies that include <strong>[{formatedList(institutions.filter(item => !isExactMatch(item)))}]</strong> as Institution will display.
                        </>
                        : physicians.filter(item => !isExactMatch(item)).length > 0 ? 
                          <> All studies that include <strong>[{formatedList(physicians.filter(item => !isExactMatch(item)))}]</strong> as Referring Physician will display. </>
                          : institutions.filter(item => !isExactMatch(item)).length > 0 ? 
                            <> All studies that include <strong>[{formatedList(institutions.filter(item => !isExactMatch(item)))}]</strong> as Institution will display. </>
                            : null
                      } 
                      {physicians.filter(item => isExactMatch(item)).length > 0 && <> All studies matching <strong>[{formatedList(physicians.filter(item => isExactMatch(item)),false)}]</strong> as Referring Physician will display. </>}
                      {institutions.filter(item => isExactMatch(item)).length > 0 && <> All studies matching <strong>[{formatedList(institutions.filter(item => isExactMatch(item)),false)}]</strong> as Institution will display. </>}
                      {ASSIGNED_MSG}
                  </InfoBox>
                :
                  physicians.length > 0
                    ?
                      <InfoBox lightBlue>
                        {physicians.filter(item => !isExactMatch(item)).length > 0 &&   <> All studies that include <strong>[{formatedList(physicians.filter(item => !isExactMatch(item)))}]</strong> as Referring Physician will display. </>}
                        {physicians.filter(item => isExactMatch(item)).length > 0 && <> All studies matching <strong>[{formatedList(physicians.filter(item => isExactMatch(item)),false)}]</strong> as Referring Physician will display. </>}
                        {ASSIGNED_MSG}
                      </InfoBox>
                    :
                      institutions.length > 0
                        ?
                          <InfoBox lightBlue>
                            {institutions.filter(item => !isExactMatch(item)).length > 0 &&   <> All studies that include <strong>[{formatedList(institutions.filter(item => !isExactMatch(item)))}]</strong> as Institution will display. </>}
                            {institutions.filter(item => isExactMatch(item)).length > 0 && <> All studies matching <strong>[{formatedList(institutions.filter(item => isExactMatch(item)),false)}]</strong> as Institution will display. </>}
                            {ASSIGNED_MSG}
                          </InfoBox>
                        :
                          null
              }
          }
          </FormValue>
          }
        </FormValue>
        {confirmExitDialog.isOpen &&
          <ConfirmExitDialog discard={closeDialog} keep={form.willSubmitForm} closeDialog={confirmExitDialog.close} />
        }
      </Dialog>
      }</WithForm>
  </>
}
