import React from 'react'
import styled from 'styled-components'
import { lazyUserTemplate } from '../../utils/lazyUserTemplate'

export const UserCard = styled(({user, className}) => {

  const userTemplate = lazyUserTemplate.read()

  return <div className={className}>
    <div className="name"><b>Name: {user.firstName + ' '+user.lastName + ' ('+userTemplate[user.userTemplate]+')'}</b></div>
    <div className="email"><b>Email:</b> {user.email}</div>
  </div>
})`
  border-radius: 6px;
  border: 1px solid rgba(0,0,0,0.25);
  padding: 0.75rem 1rem;
  margin-bottom: 1.5rem;
  .name{
    color: rgba(0,0,0,0.7);
    margin-top: 2px;
    :first-child {
      font-size: 14px;
      font-weight: 600;
      color: inherit;
      margin-top: 0;
    }
  }
  .email{
    color: rgba(0,0,0,0.7);
    margin-top: 2px;
    :first-child {
      font-size: 14px;
      font-weight: 600;
      color: inherit;
      margin-top: 0;
    }
  }
  & ~ & {
    margin-top: -1rem;
  }
`
