import { Loading } from '@startlibs/components';
import { isMobile, callIfFunction, getColor, media } from '@startlibs/utils';
import React, { useRef, useState } from 'react';
import _ from 'lodash/fp'
import styled, {css} from 'styled-components'

import {UserRow} from './UserRow'

const UserlistTable = styled.div`
  width: 100%;
  color: ${getColor('gray60')};
  background: white;
  border: 1px solid rgba(0,0,0,0.15);
  border-spacing: 0;
  border-radius: 5px;
  position: relative;
  ${props => props.isLoading && `
    pointer-events: none;
    ${UserlistTableRow} {
      border-color: transparent;
      > * {
        opacity: 0.1;
        transition: 0.5s ease;
      }
    }
  `}
  ${media.max(849)`
    border: 0;
  `}
`

export const UserlistTableRow = styled.div`
  min-height: 4rem;
  position: relative;
  border-bottom: 1px solid rgb(225,225,225);
  display: flex;
  background: white;
  ${media.min(850)`
    :nth-child(even) {
      background: #f0f6f8;
    }
    :last-child {
      border: none;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  `}
  ${media.max(849)`
    display: block;
    margin-bottom: 0.75rem;
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgba(0,0,0,0.15);
    padding: 1.5rem;
    position: relative;
  `}
  ${props => props.notEditable && css `

  `}
`
export const UserlistTableHead = styled(UserlistTableRow)`
  align-items: center;
  font-weight: 600;
  background: white;
  top: 0;
  position: sticky;
  z-index: 15;
  border-top-left-radius:5px;
  border-top-right-radius:5px;
  border-color: rgba(0,0,0,0.15) !important;
  @media all and (-ms-high-contrast:none) /* IE fixes */
   {
     position: static;
     padding-top: .5rem;
     *::-ms-backgrop, & { position: static; padding-top: .5rem; }
   }
  ${media.max(849)`
    display: none;
  `}
`
export const UserlistTableColumn = styled.div`
  flex-basis: 0;
  flex-grow: 2;
  padding: 0.75rem 0.75rem;
  :first-child {
    padding-left: 1rem;
  }
  :last-child {
    padding-right: 1rem;
  }
  &.name {
    flex-grow: 1;
    flex-basis: 160px;
    min-width: 90px;
  }
  &.user-details {
    flex-grow: 1;
    flex-basis: 300px;
    min-width: 220px;
  }
  &.userTemplate {
    flex-basis: 160px;
    min-width: 72px;
    flex-grow: 0;
  }
  &.status {
    flex-basis: 90px;
    min-width: 90px;
    flex-grow: 0;
  }
  &.edit {
    flex-shrink: 0;
    flex-basis: 355px;
    min-width: 355px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    .tooltip-wrapper {
      display: flex;
      align-items: center;
      ${media.min(835)`
        margin-left: .5rem;
      `}
      > div {
        display: flex;
        align-items: center;
      }
    }
  }
  ${media.max(849)`
    padding: 0;
    :before {
      font-weight: 600;
      display: block;
    }
    &.name {
      margin-bottom: 0.5rem;
      font-size: 16px;
      padding-right: 110px;
      padding-left: 0;
      :before {
        content: "Name: ";
        font-size: 13px;
      }
    }
    &.user-details {
      .username, .email {
        margin-bottom: 0.5rem;
        strong {
          display: block;
        }
      }
    }
    &.userTemplate {
      :before {
        content: "User template: ";
      }
    }
    &.status {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      border-radius: 5px;
      background-color: rgba(0,0,0,0.05);
      padding: .25rem .5rem;
      text-align: right;
      min-width: auto;
      max-width: 110px;
      :before {
        content: "Status: ";
      }
    }
    &.edit {
      display: flex;
      margin: 1.25rem -1.5rem -1.5rem;
      padding: 0.25rem;
      background: rgba(41, 122, 163, 0.07);
      > *, .tooltip-wrapper {
        flex-grow: 1;
        text-align: center;
        flex-shrink: 0;
        margin: 0;
      }
      .tooltip-wrapper {
        > *, .change-status {
          flex-grow: 1;
        }
      }
      ${media.max(759)`
        min-width: auto;
        > * {
          min-width: 50px;
          padding: 0.5rem 0.25rem;
        }
      `}
    }
  `}
`

const LoadingTableContent = styled.div `
  position: absolute;
  top: 4rem;
  left: 0;
  right: 0;
  height: 100%;
  height: calc(100% - 4rem);
  max-height: 80vh;
  max-height: calc(80vh - 4rem);
  ${props => !props.isMobile && css`
    ${media.desktop `
      max-width: calc(100vw - 5rem - 15px);
      left: 2.5rem;
    `}
    ${media.mobile `
      max-width: calc(100vw - 15px);
      left: 0;
    `}
  `}
`

const EmptyUserList = styled.div `
  background: rgb(240,240,240);
  min-height: 8rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  max-width: calc(100vw);
  position: sticky;
  left: 0;
`
export const Table = ({setParams, refreshList,  rows, isLoading, blankingUserlist, location, referrer, logPermission}) => {

  const [users, setUsers] = useState(rows);
  const tableRef = useRef()

  const notEditable = (defaultWorklist) => {
    return defaultWorklist == 'newhome' ? false : true
  }

  return <UserlistTable ref={tableRef} isMobile={isMobile()}>
    <UserlistTableHead isMobile={isMobile}>
      <UserlistTableColumn className="name">Name</UserlistTableColumn>
      <UserlistTableColumn className="user-details">Credentials</UserlistTableColumn>
      <UserlistTableColumn className="userTemplate">User template</UserlistTableColumn>
      <UserlistTableColumn className="status">Status</UserlistTableColumn>
      <UserlistTableColumn className="edit"> </UserlistTableColumn>
    </UserlistTableHead>
    {
      users.length
        ? users.map(user => <UserRow setParams={setParams} refreshList={refreshList}  isLoading={isLoading} location={location} referrer={referrer}
                                    key={user.id} user={user} isMobile={isMobile()} notEditable={notEditable(user.defaultWorklist)} logPermission={logPermission}
                                    setUser={(update) => setUsers(userlist => _.map(u => u.id === user.id ? callIfFunction(update, u) : u, userlist))}/>)
       : blankingUserlist
         ? <EmptyUserList css="min-height: 15rem;">{!isLoading && <div><h4>Search to view records</h4><div>Insert a search term above to display matching records</div></div>}</EmptyUserList>
         : <EmptyUserList>{!isLoading && 'No records found'}</EmptyUserList>
    }
    {isLoading && <LoadingTableContent isMobile={isMobile()}><Loading absolute size={40} borderWidth={6}/></LoadingTableContent>}
  </UserlistTable>
}
