import { Icon, Link, Loading, Tooltip } from '@startlibs/components';
import { getColor, isMobile } from '@startlibs/utils';
import { lighten } from 'polished';
import React from 'react'
import styled, { css } from 'styled-components';

import {UserlistTableColumn} from './Table';
import {UserlistTableRow} from '../userlist/Table';
import { getUsername } from '../../hooks/useAuthenticatedUser';
import { lazyUserTemplate } from '../../utils/lazyUserTemplate';
import { useActivateUserDialog } from './useActivateUserDialog';
import { useNotEditable } from './useNotEditable';
import { useSuspendUserDialog } from './useSuspendUserDialog';
import {useResetPasswordDialog} from './useResetPasswordDialog';
import { getSsoEnabled } from '../../hooks/useSsoEnabled';
import { getPacsdbName } from '../../hooks/usePacsdbName';

const TableCellButton = styled.div`
  display: inline-block;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  border-radius: 5px;
  position: relative;
  color: blue;
  :hover {
    background: rgba(41, 122, 163, 0.125);
  }
  :active {
    background: rgba(41, 122, 163, 0.175);
  }
  ${props => props.active && `
    background: rgba(41, 122, 163, 0.125);
  `}
  ${Icon} {
    font-size: 16px;
    vertical-align: -3px;
    color: rgba(0,0,FFF,0.57);
  }
  i {
    color: rgba(0,0,FFF,0.4);
  }
`
export const TextButton = styled.a`
  text-decoration: none;
  color: ${getColor('main')};
  padding: 0.5rem 0.75rem;
  font-weight: 600;
  border-radius: 5px;
  user-select: none;
  position:relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & ~ & {
    margin-left: 0.5rem;
  }
  :hover {
    background-color: rgba(0, 139, 210, 0.1);
  }
  :active {
    background-color: rgba(0, 139, 210, 0.15);
  }
  &.change-status {
    min-width: 68px;
  }
  &.reset-pass {
    margin-left: .5rem;
  }
  ${props => props.focused && css`
    background-color: rgba(0, 139, 210, 0.1);
    color: ${getColor('main')};
  `}
  ${props => props.isLoading && css`
    background-color: rgba(0, 139, 210, 0.1);
    color: ${getColor('main')};
    pointer-events: none;
  `}
  ${props => props.started && css`
    background-color: ${props => lighten(0.4, props.theme.colors.success)};
    color: ${getColor('success')};
    pointer-events: none;
  `}
  ${props => props.disabled && css`
    color: ${getColor('gray90')};
    pointer-events: none;
  `}
  ${Loading} {
    display: inline-block;
    margin-right: 4px;
    vertical-align: sub;
  }
  ${Icon} {
    font-size: 18px;
    margin-right: 4px;
    vertical-align: bottom;
  }
  ${props => props.notEditable && css`
    opacity: 0.5;
  `}
`

var authUser = ''
getUsername().then((response) =>{
  authUser = response
})

export const UserRow = ({ setParams, user, refreshList, setUser, notEditable, location, referrer, logPermission }) => {

  const resetPasswordDialog = useResetPasswordDialog({user, location, referrer})
  const suspendDialog = useSuspendUserDialog({ user, setUser, location, referrer})
  const activateDialog = useActivateUserDialog({ user, setUser, location, referrer })
  const notEditableDialog = useNotEditable({ user, type: 'edit' })
  const notEditableSuspendDialog = useNotEditable({ user, type: 'reset' })

  const passwordNotChangeable = getSsoEnabled() && ((user.login.indexOf('@purview.net') < 0) && (user.login.indexOf('purview@'+getPacsdbName()) < 0))

  const goToAuditLogs = (user) => {

    var url = referrer;

    if(url.search('.php') > 0){
      let parts = url.split('/')
      url = url.replace(parts[parts.length-1],'')
    }

    if(url.charAt(url.length-1) != '/'){
      url = url+'/'
    }
    
    url = url+`audit_logs_elk.php?user=${user}`
    window.open(url, "_top")

  }

  const userTemplate = lazyUserTemplate.read()

  return <UserlistTableRow isMobile={isMobile()} notEditable={notEditable}>

    <UserlistTableColumn className="name">{user.firstName + ' ' + user.lastName}</UserlistTableColumn>
    <UserlistTableColumn className="user-details">
      <div className="username"><strong>Username: </strong> {user.login}</div>
      <div className="email"><strong>Email: </strong> {user.email}</div>
    </UserlistTableColumn>
    <UserlistTableColumn className="userTemplate">{userTemplate[user.userTemplate]}</UserlistTableColumn>
    <UserlistTableColumn className="status">{user.active ? 'Active' : 'Suspended'}</UserlistTableColumn>
    <UserlistTableColumn className="edit">
      {notEditable
        ?
        <TextButton onClick={notEditableDialog} notEditable>
          <Icon icon="lock" /> Edit
          </TextButton>
        :
        <TextButton as={Link} to={`edit/${user.id}`}> <Icon icon="edit-box" /> Edit </TextButton>
      }
      {logPermission &&
      <TextButton onClick={() => {goToAuditLogs(user.login)}}> <Icon icon="activity-logs" /> Logs </TextButton> }

      <TextButton onClick={resetPasswordDialog} className="reset-pass" disabled={passwordNotChangeable} notEditable={passwordNotChangeable}>
        Reset password
      </TextButton>

      {
        user.active
          ? <div className="tooltip-wrapper"><Tooltip content={authUser == user.login ? "You are not allowed to suspend your own user. This prevents you from losing access to this portal." : ""}>
            <TextButton onClick={suspendDialog} notEditable={authUser == user.login} disabled={authUser == user.login} className="change-status">
              Suspend
              </TextButton>
            </Tooltip></div>
          : <TextButton onClick={activateDialog} className="change-status">
            Activate
            </TextButton>
      }
    </UserlistTableColumn>
  </UserlistTableRow>
}
