import { Button } from '@startlibs/components';
import { Errors, ConfirmDialog, useConfirmDialog, WithForm } from '@startlibs/form';
import React from 'react'
import { getJwt } from '../../hooks/useJwt';
import { jwtPostFetcher } from '../../utils/authFetch';
import _ from 'lodash/fp'
import { UserCard } from './UserCard';

export const useResetPasswordDialog = ({user, location, referrer}) => useConfirmDialog(

  <ConfirmDialog
    action={(e) => {
      return jwtPostFetcher(getJwt())('/api/customer-users/reset-password', e)
    }}
    onSuccess={(e) => {
      // console.log(e)
    }}
    onFailure={(e, [resp], form) => {
      // console.log(resp)
    }}
    values={{ email: user.email, userId: user.id, brandedUrl: referrer}}
    title="Confirm password reset"
    confirm={<Button highlight>Reset password</Button>}
    notify={"Password reset email sent."}
    cancelLabel={"Cancel"}
  >
    <p>The user will receive an email with a new password:</p>
    <UserCard user={user}/>
    <p>Confirm password reset?</p>
    <Errors/>
  </ConfirmDialog>
)
