import React from 'react'
import {postFetcher, wrapLazy} from '@startlibs/utils'
import { getJwt, setJwt } from '../hooks/useJwt'
import { jwtGetFetcher } from './authFetch'


export const lazyUserTemplate = wrapLazy(()=>{

  return jwtGetFetcher(getJwt())('/api/customer-users/user-templates')
  
})