let resolveUsername = null
let username = new Promise((res) => resolveUsername = res)

export const setUsername = (newUsername,sessionKey) => {
  username = newUsername
  resolveUsername(username)
  if (sessionKey) {
    window.localStorage.setItem(sessionKey,newUsername)
  }
}
export const getUsername = () => username