let resolvePacsdbName = null
let pacsdbName = new Promise((res) => resolvePacsdbName = res)

export const setPacsdbName= (newPacsdbName, sessionKey) => {
  pacsdbName = newPacsdbName
  resolvePacsdbName(pacsdbName)
  if (sessionKey) {
    window.localStorage.setItem(sessionKey + "-pacsdbName", newPacsdbName)
  }
}
export const getPacsdbName= () => pacsdbName