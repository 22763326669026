import { ConfirmDialog, Errors, useConfirmDialog, WithForm } from '@startlibs/form';
import React from 'react'
import _ from 'lodash/fp'
import {UserCard} from './UserCard'

export const useNotEditable = ({user, type}) => useConfirmDialog(

  <ConfirmDialog
      title= {type === 'edit' ? "Unable to edit this user" : "Unable to reset password"}
      cancelLabel={"Close"}
  >
        <p>This user is currently not editable via user management panel.</p>
        <UserCard user={user}/>
        <p>Please contact your system administrator to make changes to this user.</p>
      <Errors/>
    </ConfirmDialog>
  )
