let resolveIsVivaLite = null
let isVivaLite = new Promise((res) => resolveIsVivaLite = res)

export const setIsVivaLite = (newIsVivaLite, sessionKey) => {
  isVivaLite = newIsVivaLite
  resolveIsVivaLite(isVivaLite)
  if (sessionKey) {
    window.localStorage.setItem(sessionKey + "-isVivaLite", newIsVivaLite)
  }
}
export const getIsVivaLite = () => isVivaLite
