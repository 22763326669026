import {Button, Icon, ContextMenu, Li, Loading} from '@startlibs/components';
import {TextInput } from '@startlibs/form'
import {
  callIfFunction,
  getColor,
  media,
  isMobile,
  wrapLazy
} from '@startlibs/utils';
import {useConstant, Slot, usePopupToggle, useToggle, Fill, useRefState} from '@startlibs/core'
import React, { useEffect, useState, } from 'react'
import _ from 'lodash/fp';
import styled, {css} from 'styled-components'

import {FetchErrorDialog} from './errors/FetchErrorDialog'
import {Pagination} from '../components/Pagination'
import { PurviewFooter } from '../components/PurviewFooter'
import {SectionHeading } from '../components/PageLayout';
import {Table} from './userlist/Table';
import {callUpdateParams } from '../hooks/useUpdateParams'
import { getJwt } from '../hooks/useJwt';
import { jwtGetFetcher } from '../utils/authFetch';
import {stickyOnDesktop } from '../utils/stickyOnDesktop';

const MaxResultsMenu = styled(ContextMenu)`
  li > * {
    padding: 11px 2rem;
  }
`

const UserlistPageContainer = styled.div`
  padding: 2rem 1.5rem 90px;
  margin: 0 auto;
  position: relative;
  min-width: 300px;
  max-width: 90rem;
  min-height: 100vh;
  ${props => props.isMobile && css`
    min-width: 300px;
  `}
  h3 {
    font-weight: 600;
  }
  p {
    font-size: 13px;
  }
  ${media.max(799)`
    max-width: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  `}
  ${media.max(520)`
    padding-top: 6.25rem;
  `}
`

const UserListCard = styled.div`
  & ~ ${SectionHeading} {
    margin-top: 3rem;
  }
  padding: 2.5rem;
  background: white;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin: 0 auto;
  ${media.max(520)`
    padding: 1.5rem;
  `}
`

const UserlistHeaderComponent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  z-index: 30;
  position: relative;
  .left-wrapper {
    min-width: 300px;
    flex-grow: 1;
  }
  ${media.max(759)`
    flex-wrap: wrap;
    .right-wrapper {
      position: absolute;
      right: 0;
      top: 3.5rem;
    }
  `}
  ${media.max(600)`
    .right-wrapper {
      top: 4rem;
    }
  `}
  ${media.max(450)`
    .right-wrapper {
      width: 100%;
      position: static;
      margin-top: .5rem;
      ${Button} {
        width: 100%;
        margin-left: 0;
        margin-top: .5rem;
      }
    }
  `}
`
const LinkButton = styled.a`
  position: relative;
  display: inline-block;
  text-decoration: underline;
  user-select: none;
  cursor: pointer;
`

const UsersIcon = styled(Icon)`
  color: grey;
  border-radius: 50%;
  width: 2.5rem;
  text-align: center;
  line-height: 2.5rem;
  display: inline-block;
  font-size:24px;
  position:relative;
  margin: 0 -4px;
`

const SortButton = styled(Button)`
    ${Icon} {
      margin-right: 4px;
    }
`

const ContextMenuWithoutIcons = styled(ContextMenu)`
  li > * {
    padding: 11px 1.5rem;
  }
`
const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${getColor('gray120')};
  ${media.max(759)`
    flex-wrap: wrap;
  `}
`
const SearchInput = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 1rem;
  max-width: 24rem;
  width: 100%;
  ${media.max(759)`
    max-width: 100%;
    margin-bottom: .5rem;
    margin-right: 0;
  `}
  ${media.max(450)`
    margin-right: 0;
    margin-bottom: .5rem;
    max-width: 100%;
  `}

`
const ClearSearchButton = styled(Icon)`
  color: ${getColor('gray90')};
  font-size: 12px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  border-radius: 50%;
  background: rgba(0,0,0,0.1);
  :hover {
    background: #f7d3d4;
    color: ${getColor('alert')};
  }
`

const LoadingUserlist = styled.div`
  border-radius: 5px;
  height: 26rem;
  ${stickyOnDesktop()}
`
const FooterContainer = styled.div`
  ${props => !props.isMobile && css`
    min-width: ${props => props.visibleColumnsAmount > 8 ? '115rem' : '100rem'};
  `}
`
const UserlistPurviewFooter = styled(PurviewFooter)`
  ${stickyOnDesktop()}
  margin-left: 0;
  margin-right: 0;
`

const PaginationContainer = styled.div`
  text-align: center;
  margin-top: 2rem;
  ${stickyOnDesktop()}
`

const ALL = 'ALL'
const ACTIVE = 'ACTIVE'
const SUSPENDED = 'SUSPENDED'

let authenticated = process.env.NODE_ENV !== 'development'

const lazyUserList = wrapLazy((params) => {

  const pageNumber =  params.page > 0 ? params.page - 1 : 0;

  var sortColumn = ''
  if(params.sortElement){
    switch(params.sortElement){
      case 'name':
        sortColumn = 'firstName,lastName';
        break;
      case 'status':
        sortColumn = 'active';
        break;
      case 'username':
        sortColumn = 'login';
        break;
      case 'user template':
        sortColumn = 'userTemplate';
        break;
      default:
        sortColumn = params.sortElement
    }
  }

  let statusFilter = 
    params.statusFilter === ALL 
      ? '' 
      : params.statusFilter == SUSPENDED 
        ? '&active=false' 
        : '&active=true'
  return jwtGetFetcher(getJwt())('/api/customer-users?filter='+params.searchString+'&page='+pageNumber+'&size='+params.maxResults+'&sort='+sortColumn+',asc'+statusFilter)

})

export const UserList = ({location, referrer, logPermission}) => {

  const [searchString, setSearchString] = useState(() => '');
  const [params, rawSetParams] = useState({searchString: '', page: 1, maxResults: 20, sortElement: 'name', location: location, statusFilter: ALL});
  const loading = useToggle()

  const updateParams = (params) => {
    callUpdateParams(params)
  }

  const debounceSetParams = useConstant(_.debounce(500, rawSetParams))

  const setParams = (updater, debounce) => {
    const verifyPage = (updater) => (state) => {
      const updatedState = callIfFunction(updater, state)
      if (
        state.searchString !== updatedState.searchString ||
        state.sortElement !== updatedState.sortElement
      ) {
        return _.set('page', 1, updatedState)
      } else if (state.maxResults !== updatedState.maxResults) {
        const firstRow = ((state.maxResults || 20) * (updatedState.page - 1) + 1)
        return _.set('page', Math.max(Math.floor(firstRow / (updatedState.maxResults || 20)) + 1, 1), updatedState)
      } else {
        return updatedState
      }
    }
    if (debounce) {
      debounceSetParams(verifyPage(updater))
    } else {
      setTimeout(() => rawSetParams(verifyPage(updater)), 0)
    }
    setTimeout(loading.open, 0)
  }

  return <>
    <UserlistPageContainer visibleColumnsAmount={5} isMobile={isMobile()}>
      <SectionHeading>
        <h3>User management</h3>
      </SectionHeading>
      <UserListCard>
        <UserlistHeader
          searchString={searchString}
          setSearchString={setSearchString}
          params={params}
          setParams={setParams}
          loading={loading}
        />
        <React.Suspense
          fallback={null}>
          <UserlistTable
            blankUserlist={true}
            updateParams={updateParams}
            loading={loading}
            params={params}
            setParams={setParams}
            location={location}
            referrer={referrer}
            logPermission={logPermission}
          />
        </React.Suspense>
      </UserListCard>
    </UserlistPageContainer>
    <FooterContainer
      isMobile={isMobile()}
    >
      <UserlistPurviewFooter />
    </FooterContainer>
  </>
}

const UserlistHeader = ({loading, params, setParams}) => {

  const query = useToggle(params.searchString || '');
  const sortBy = useToggle(params.sortElement || '');
  const maxResults = useToggle(params.maxResults || '');
  const statusFilter = useToggle(params.statusFilter || ALL)

  const SortOptionsMenu = usePopupToggle();
  const FilterOptionsMenu = usePopupToggle();
  const maxResultsMenu = usePopupToggle()

  const updateSearch = (v) => {
    query.openWith(v);
    setParams(_.set('searchString', v), true);
  }

  const updateSort = (v) => {
    sortBy.openWith(v)
    setParams(_.set('sortElement', v), true);
  }

  const updateMaxResult = (v) => {
    maxResults.openWith(v)
    setParams(_.set('maxResults', v), true)
  }

  const updateStatusFilter = (v) => {
    statusFilter.openWith(v)
    setParams(_.set('statusFilter', v), true)
  }

  return <UserlistHeaderComponent>
    <div css="flex-grow:1;">
      <SearchInputWrapper>
        <SearchInput>
          <TextInput raw value={query.isOpen} setValue={updateSearch} placeholder="Search users" />
          {query.isOpen && <ClearSearchButton icon="x" onClick={() => updateSearch("")} />}
        </SearchInput>
        <div css="margin-right: 1rem;">
          <Button.Link highlight to="/new">
            Add user
          </Button.Link>
        </div>
        <div>
          <Slot name="QueryCount" />
          <div className="nowrap">Showing <LinkButton onClick={maxResultsMenu.open}>{(maxResults.isOpen || 20)} per page
            {
              maxResultsMenu.isOpen &&
              <MaxResultsMenu offset="0 4">
                <Li label="20" onClick={() => updateMaxResult(20)} />
                <Li label="50" onClick={() => updateMaxResult(50)} />
                <Li label="100" onClick={() => updateMaxResult(100)} />
              </MaxResultsMenu>
            }
          </LinkButton>
          </div>
        </div>
      </SearchInputWrapper>
    </div>
    <div className="right-wrapper">
      <div>
        <Button outline icon="sort" onClick={SortOptionsMenu.open}>{sortBy.isOpen ? 'Sort by ' + sortBy.isOpen : 'Sort by name'}
          {
            SortOptionsMenu.isOpen &&
            <ContextMenuWithoutIcons>
              <Li label="Sort by name" onClick={() => updateSort('name')} />
              <Li label="Sort by username" onClick={() => updateSort('username')} />
              <Li label="Sort by email" onClick={() => updateSort('email')} />
              <Li label="Sort by user template" onClick={() => updateSort('user template')} />
              <Li label="Sort by status" onClick={() => updateSort('status')} />
            </ContextMenuWithoutIcons>
          }
        </Button>
        <Button outline icon="filter" onClick={FilterOptionsMenu.open}>{statusFilter.isOpen === ALL ? "All users" : statusFilter.isOpen  === SUSPENDED ? "Suspended users" : "Active users"}
        {
          FilterOptionsMenu.isOpen &&
          <ContextMenuWithoutIcons>
            <Li label="Show all users"  onClick={() => updateStatusFilter(ALL)} />
            <Li label="Show only active users"  onClick={() => updateStatusFilter(ACTIVE)} />
            <Li label="Show only suspended users"  onClick={() => updateStatusFilter(SUSPENDED)} />
          </ContextMenuWithoutIcons>
        }
        </Button>
      </div>
    </div>
  </UserlistHeaderComponent>
}

const UserlistTable = React.memo(({params, setParams, loading, blankUserlist, location, referrer, logPermission, ...props}) => {

  const [userlist, setUserlist] = useState({userlist: {rows: [], total: 0},key: Date.now(),blankingUserlist: true})
  const lastParams = useRefState(params)
  const lastSuccessParams = useRefState(params)
  const errorDialog = useToggle()
  const currentPage = Number(params.page) || 1
  const maxResults = Number(params.maxResults) || 20

  const loadList = (params) => {
    lazyUserList.use(params).promise().then((response) => {
      setUserlist({ userlist: { rows: response.users, total: response.totalCount }, key: Date.now(), blankingUserlist: response.totalCount > 0 ? false : true });
      callIfFunction(loading.close);
    });
  }

  const refreshList = () => loadList(params)

  useEffect(() => {
    loading.open()
    loadList(params);
  }, [params])

  if (!userlist) {
    return <>
      <Fill name="QueryCount"><b>Loading...</b></Fill>
      <LoadingUserlist><Loading size={40} borderWidth={6} absolute /></LoadingUserlist>
    </>
  }

  const { userlist: { blankingUserlist, total, rows }, key } = userlist;

  return <>
    <Fill name="QueryCount"><b>{loading.isOpen ? 'Loading...' : <>{total} user{total !== 1 ? "s" : ""}{params.searchString && " found"}</>}</b></Fill>
    <Fill name="Pagination"><Pagination
      setPageParam={(page) => () => setParams(_.set('page', page))}
      currentPage={currentPage}
      totalPages={Math.ceil((Number(total)) / maxResults)}
    /></Fill>
    <Table
      key={key}
      blankingUserlist={blankingUserlist}
      refreshList={refreshList}
      isLoading={loading.isOpen}
      setParams={setParams} {...props}
      queryCount={Number(total)}
      rows={rows}
      location={location}
      referrer={referrer}
      logPermission={logPermission}
    />
    {
      errorDialog.isOpen &&
      <FetchErrorDialog closeDialog={errorDialog.close} />
    }
    <PaginationContainer><Slot name="Pagination" /></PaginationContainer>
  </>
})
