import { Button } from '@startlibs/components';
import { ConfirmDialog, useConfirmDialog } from '@startlibs/form';
import React from 'react'
import _ from 'lodash/fp'
import { UserCard } from './UserCard'
import { jwtPostFetcher } from '../../utils/authFetch';
import { getJwt } from '../../hooks/useJwt';

export const useActivateUserDialog = ({ user, setUser, location, referrer }) => useConfirmDialog(

  <ConfirmDialog
    action={(e) => {
      return jwtPostFetcher(getJwt())('/api/customer-users/change-status', e)
    }}
    onSuccess={(e) => {
      setUser(_.set('active', true));
    }}
    onFailure={(e, [resp], form) => {
      if (resp.status == 400) { 
        form.setError("submit", "Sorry. User was not activated.")
      }
    }}
    // values={{ active: false, id: user.id }}
    values={{ active: false, userId: user.id, brandedUrl: referrer}}
    title="Confirm activating user"
    confirm={<Button highlight>Activate user account</Button>}
    notify={"User activated successfully."}
    cancelLabel={"Cancel"}
  >
    <p>Please confirm activation of this currently suspended user: </p>
    <UserCard user={user} />
    <p>Are you sure you want to activate this user?</p>
    {/* <Errors/> */}
  </ConfirmDialog>
)
