let resolveSsoEnabled = null
let ssoEnabled = new Promise((res) => resolveSsoEnabled = res)

export const setSsoEnabled = (newSsoEnabled, sessionKey) => {
  ssoEnabled = newSsoEnabled
  resolveSsoEnabled(ssoEnabled)
  if (sessionKey) {
    window.localStorage.setItem(sessionKey + "-ssoEnabled", newSsoEnabled)
  }
}
export const getSsoEnabled = () => ssoEnabled