import {DialogFooter, DialogIcon, Icon} from '@startlibs/components'
import {Errors} from '@startlibs/form'
import {css} from 'styled-components'
import {getColor} from '@startlibs/utils'

export default {
  startlibs: {
    Button: css`
          box-shadow: inset 0 -3px 0 0 rgba(0,0,0,0.15);
          border-radius: 5px;
          ${props => props.small && `
            box-shadow: inset 0 -2px 0 0 rgba(0,0,0,0.15);
          `}
          ${Icon} {
            vertical-align: -5px;
          }
      `,
    ContextMenu: `
        border-radius: 5px;
      `,
    Popup: `
        border-radius: 5px;
      `,
    Tooltip: `
        border-radius: 5px;
    `,
    ListItem: css`
        ${props => props.hover && css`
          & > a, & > label, .whiteOnHover {
            background-color: ${getColor('main')};
            color: white;
          }
        `}
        ${props => props.highlight && css`
            color: ${getColor('main')};
            font-weight: 600;
        `}
      `,
    Dialog: css`
        background-color: white;
        border-radius: 6px;
        h2 {
          border-bottom: none;
          padding-bottom: 0;
        }
        ${DialogFooter} {
          border-top: none;
        }
        ${DialogIcon} {
          font-size: 100px;
        }
      `,
    FieldLabel: `
        font-weight: 600;
      `,
    TextInput: `
        border-radius: 5px;
      `,
    /*IconRadioBox: css`
        border-radius: 5px;
        ${Icon} {
          font-size: 120px;
          line-height: 56px;
        }
        ${IconRadioBox.Text} {
          padding: 1rem;
          box-shadow: inset 0 -3px 0 0 rgba(0,0,0,.15);
        }
      `,*/
    ErrorItem: css`
        background: ${getColor('alert')};
        color: white;
        border: 0;
        ${Errors.CloseIcon} {
          color: white;
          background-color: rgba(0, 0, 0, 0.2);
          width: 20px;
          height: 20px;
          line-height: 20px;
          &:hover {
            background: rgba(0,0,0,0.25);
          }
        }
        a {
          color: white;
          font-weight: 600;
        }
      `,
    ToggleCheckbox: css`
      width: 2.75rem;
      height: 18px;
      border-radius: 18px;
      background: ${props => props.checked ? getColor('main') : 'rgba(0,0,0,0.2)'};
      display: inline-block;
      position: relative;
      font-weight: 600;
      &.disabled {
        opacity: 0.4;
      }
      :after {
        transition: none;
        width: 14px;
        height: 14px;
        background: white;
        ${props => props.checked ? css`
          margin-left: -2px;
          `: css`
          margin-left: 2px;
        `}
      }
    `,
  },
  Field: {margins: {none: '0', big: '2rem', 'default': '1rem'}},
  colors: {
    gray30: '#1e1e1e',
    gray60: '#3c3c3c',
    gray90: '#5a5a5a',
    gray120: '#787878',
    gray150: '#969696',
    gray180: '#b4b4b4',
    gray210: '#d2d2d2',
    gray240: '#f0f0f0',
    main: '#008bd2',
    secondary: '#28AAE1',
    alert: '#C3282D',
    warning: '#e6932e',
    success: '#39B54A',
    physician: '#00a99d',
    organization: '#ed1e79',
    lightGreen: '#E7F2E9',
    lightYellow: '#F8F2DF',
    lightBlue: '#EFF4F7'
  },
  iconFont: 'purview-icons',
  icons: {
    'activity-logs': '\ue96f',
    'audit': '\ue96a',
    'star': '\ue969',
    'star-line': '\ue968',
    'divisions': '\ue967',
    'users-line': '\ue966',
    'external-report': '\ue965',
    'sign-doc': '\ue964',
    'svs': '\ue963',
    'user-line': '\ue95b',
    'physician-line': '\ue95c',
    'info': '\ue95d',
    'question-line': '\ue95e',
    'payment': '\ue95f',
    'clinical-sheet': '\ue960',
    'dicom-no-text': '\ue961',
    'email-line': '\ue962',
    'note': '\ue935',
    'delete': '\ue934',
    'help': '\ue933',
    'download': '\ue930',
    'print': '\ue931',
    'email': '\ue932',
    'arrow-up-line': '\ue92c',
    'arrow-down-line': '\ue92d',
    'arrow-left-line': '\ue92e',
    'arrow-right-line': '\ue92f',
    'hlogo-right': '\ue929',
    'hlogo-left': '\ue92a',
    'hlogo-center': '\ue92b',
    'reload': '\ue927',
    'image': '\ue928',
    'external-link-line': '\ue923',
    'edit': '\ue924',
    'reorder': '\ue925',
    'move': '\ue926',
    'bold': '\ue91b',
    'italic': '\ue91c',
    'underline': '\ue91d',
    'strikethrough': '\ue91e',
    'insertUnorderedList': '\ue91f',
    'insertOrderedList': '\ue920',
    'outdent': '\ue921',
    'indent': '\ue922',
    'sort': '\ue91a',
    'zip-file': '\ue919',
    'calendar': '\ue917',
    'lock': '\ue918',
    'directories': '\ue913',
    'files': '\ue914',
    'failure': '\ue915',
    'external-link': '\ue916',
    'edit-box': '\ue911',
    'sign-out': '\ue912',
    'share': '\ue90a',
    'arrow-down': '\ue903',
    'arrow-up': '\ue904',
    'arrow-left': '\ue905',
    'arrow-right': '\ue906',
    'upload': '\ue907',
    'view': '\ue908',
    'settings': '\ue909',
    'search': '\ue90b',
    'check': '\ue90c',
    'x': '\ue90d',
    'plus-circle': '\ue90e',
    'notification': '\ue90f',
    'user': '\ue900',
    'physician': '\ue901',
    'organization': '\ue902',
    'report': '\ue936',
    'checked-report': '\ue937',
    'station': '\ue938',
    'warning': '\ue939',
    'premium': '\ue93a',
    'credit-card': '\ue93b',
    'clock': '\ue93d',
    'zoom-in': '\ue93e',
    'zoom-out': '\ue93f',
    'minus': '\ue941',
    'plus': '\ue910',
    'home-line': '\ue940',
    'home': '\ue942',
    'hide': '\ue943',
    'send': '\ue944',
    'link': '\ue945',
    'pdf': '\ue947',
    'video': '\ue948',
    'image-line': '\ue949',
    'zip': '\ue94a',
    'dicom': '\ue94d',
    'unavailable': '\ue94f',
    'return': '\ue94e',
    'qrcode': '\ue950',
    'phone': '\ue951',
    'download-cd': '\ue952',
    'attachments': '\ue953',
    'launch': '\ue954',
    'send-line': '\ue955',
    'settings-line': '\ue956',
    'study-notes': '\ue957',
    'link-thin': '\ue958',
    'draggable': '\ue977',
    'draggable-corner': '\ue978',
    'move-group': '\ue979',
    'import': '\ue97a',
    'imported': '\ue97b',
    'remove-from-group': '\ue97c',
    'x-circle-undo': '\ue97d',
    'x-circle': '\ue97e',
    'start-line-ultra-light': '\ue97f',
    'feedback': '\ue980',
    'play': '\ue981',
    'filter': '\ue982',
  }
}
