import { RadioboxGroup, SimpleRadiobox, ToggleCheckbox } from '@startlibs/form';
import { SplitColumnsContainer } from '@startlibs/components';
import { getColor, media } from '@startlibs/utils';
import styled from 'styled-components';

import { BoxPageContainer, BoxPageLayout } from './BoxPageLayout';

export const EditUserPage = styled(BoxPageLayout)`
  ${BoxPageContainer} {
    padding-top: 2rem;
  }
  .content{
    margin: 0px;
    padding: 1rem 0;
    max-width: 100%;
    min-width: auto;
    .link {
      color: ${getColor('main')};
      font-weight: 600;
      cursor: pointer;
    }
  }
  .form {
    .username-field {
      max-width: calc(50% - .5rem);
      ${media.max(979)`
        max-width: 100%;
      `}
    }
    .study-access-wrapper {
      margin: 2rem 0;
    }
    ${RadioboxGroup} {
      ${SimpleRadiobox} {
        white-space: nowrap;
      }
    }
  }
  .form-right{
    padding: 2rem;
    border: 1px solid ${getColor('gray210')};
    border-radius: 8px;
    .tooltip-container {
      > :nth-child(1) {
        margin-top: 1rem;
        flex-basis: 47%;
        flex-grow: 1;
        max-width: calc(50% - 1rem);
        ${media.max(979)`
          display: block;
          max-width: 100%;
          margin-top: 0;
        `}
      }
    }
    ${ToggleCheckbox} ~ ${ToggleCheckbox} {
        margin-top: 1rem;
        ${media.max(850)`
          margin-top: 0;
        `}
      }
    }
    ${media.min(980)`
      min-width: 510px;
    `}
  }
  ${media.between(570,850)`
    .form .username-field {
      max-width: calc(50% - .5rem);
    }
    .form, .form-right {
      ${SplitColumnsContainer} {
        flex-wrap: wrap;
        display: flex;
        > * {
          flex-basis: 47%;
          flex-grow: 1;
          ~ * {
            margin-left: 1rem;
          }
        }
      }
    }
  `}
  ${media.min(850)`
    .form-right {
      margin-left: 3rem;
      min-width: 500px;
    }
  `}
  ${media.max(520)`
    .content, p {
      padding: 1rem;
    }
  `}
`
